import request from '@/utils/request'

const adminApi = {
  Login: '/login',
  Logout: '/logout',
  SavePwd:'/manager/reset/password'
}

export function login (parameter) {
  return request({
    url: adminApi.Login,
    method: 'post',
    data: parameter
  })
}

export function logout () {
  return request({
    url: adminApi.Logout,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}


export function savePwd (parameter) {
  return request({
    url: adminApi.SavePwd,
    method: 'post',
    data: parameter
  })
}
