// dynamic-routers.js

const dynamicRoutes = [
  // {
  //   path: '/dashboard',
  //   name: '数据看板',
  //   icon: 'Histogram',
  //   children: [
  //     {
  //       path: '/dashboard',
  //       name: '数据看板',
  //     },
  //   ],
  // },
  {
    path: '/user',
    name: '用户管理',
    icon: 'User',
    children: [
      {
        path: '/user',
        name: '用户管理',
      },
    ],
  },
  {
    path: '/channel',
    name: '渠道管理',
    icon: 'Guide',
    children: [
      {
        path: '/channel',
        name: '渠道管理',
      },
    ],
  },
  {
    path: '/teacher',
    name: '教师管理',
    icon: 'Avatar',
    children: [
      {
        path: '/teacher',
        name: '教师管理',
      },
    ],
  },
  {
    path: '/product',
    name: '产品管理',
    icon: 'Operation',
    children: [
      {
        path: '/product',
        name: '产品管理',
      },
    ],
  },
  {
    path: '/order',
    name: '订单管理',
    icon: 'ShoppingCart',
    children: [
      {
        path: '/order',
        name: '订单管理',
      },
    ],
  },
];

export default dynamicRoutes;
