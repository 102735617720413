// index.js

import { createRouter, createWebHistory } from 'vue-router';
import MainLayout from '../layouts/MainLayout.vue';
import Login from '../views/login/Login.vue';
import MobileLogin from '../views/m-login/Login.vue';
import DingLogin from '../views/ding-login/Login.vue';
import dynamicRoutes from './dynamic-routes'; // 导入动态路由配置
// import { ElMessage } from 'element-plus';
import NotFound from '../views/404.vue'; // 导入404页面组件

const routes = [
  {
    path: '/login',
    component: Login,
    meta: { noNavbar: true, title: "登录-智讯系统数据管理后台" } // 添加路由元信息，表示不需要导航栏
  },
  {
    path: '/dashboard',
    component: MainLayout,
    meta: { requiresAuth: true }, // 需要验证登录才能访问的页面
    children: [
      {
        path: '/dashboard',
        component: () => import('@/views/dashboard/Workplace'),
        meta: { title: "数据看板-智讯系统数据管理后台" }
      }
    ],

  },
  {
    path: '/user',
    component: MainLayout,
    meta: { requiresAuth: true }, // 需要验证登录才能访问的页面
    children: [
      {
        path: '/user',
        component: () => import('@/views/user/Index'),
        meta: { title: "用户管理-智讯系统数据管理后台" }
      },
      {
        path: '/user/detail',
        component: () => import('@/views/user/modules/UserDetail'),
        meta: { title: "用户详情-智讯系统数据管理后台" }
      }
    ]
  },
  {
    path: '/channel',
    component: MainLayout,
    meta: { requiresAuth: true }, // 需要验证登录才能访问的页面
    children: [
      {
        path: '/channel',
        component: () => import('@/views/channel/Index'),
        meta: { title: "渠道管理-智讯系统数据管理后台" }
      },
      {
        name: "childChannel",
        path: '/channel/child',
        component: () => import('@/views/channel/modules/ChildChannel'),
        meta: { title: "子渠道管理-智讯系统数据管理后台" }
      }
    ]
  },
  {
    path: '/teacher',
    component: MainLayout,
    meta: { requiresAuth: true }, // 需要验证登录才能访问的页面
    children: [
      {
        path: '/teacher',
        component: () => import('@/views/teacher/Index'),
        meta: { title: "教师管理-智讯系统数据管理后台" }
      }
    ]
  },
  {
    path: '/product',
    component: MainLayout,
    meta: { requiresAuth: true }, // 需要验证登录才能访问的页面
    children: [
      {
        path: '/product',
        component: () => import('@/views/product/Index'),
        meta: { title: "产品管理-智讯系统数据管理后台" }
      }
    ]
  },
  {
    path: '/order',
    component: MainLayout,
    meta: { requiresAuth: true }, // 需要验证登录才能访问的页面
    children: [
      {
        path: '/order',
        component: () => import('@/views/order/Index'),
        meta: { title: "订单管理-智讯系统数据管理后台" }
      }
    ]
  },
  {
    path: '/radar',
    meta: { requiresAuth: true },
    children: [
      {
        path: "/radar",
        component: () => import('@/views/radar/Index'),
        beforeEnter: (to, from, next) => {
          const hasIdParam = to.query.id != null
          if (hasIdParam) {
            // 只有当带有 id 参数时才允许进入路由
            next()
          } else {
            // 否则重定向到其他页面或者进行其他处理
            next('/404')
          }
        }
      }
    ]
  },
  {
    path: '/m-login',
    component: MobileLogin,
    meta: { noNavbar: true, title: "登录-智讯系统数据管理后台" } // 添加路由元信息，表示不需要导航栏
  },
  {
    path: '/m-homework',
    meta: { requiresAuth: true }, // 需要验证登录才能访问的页面
    children: [
      {
        path: '/m-homework',
        component: () => import('@/views/m-homework/Index'),
        meta: { title: "布置作业-智讯系统数据管理后台" }
      }
    ]
  },
  {
    path: '/ding-login',
    component: DingLogin,
    meta: { noNavbar: true, title: "登录-智讯系统数据管理后台" } // 添加路由元信息，表示不需要导航栏
  },
  {
    path: '/',
    meta: { requiresAuth: true }, // 需要验证登录才能访问的页
    redirect: /* eslint-disable no-unused-vars */ to => {
      // 根据设备类型重定向
      const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      const isDingTalk = /DingTalk/i.test(navigator.userAgent); // 新增：判断是否在钉钉中打开网页
      const accessToken = localStorage.getItem("ACCESS_TOKEN");
      if (isDingTalk && (accessToken == 'null' || accessToken == 'undefined' || accessToken == null)) {
        return '/ding-login'
      } else if (isMobileDevice && (accessToken == 'null' || accessToken == 'undefined' || accessToken == null)) {
        return '/m-login';
      } else if ((isMobileDevice && accessToken) || (isDingTalk && accessToken)) {
        return '/m-homework';
      } else if (!isMobileDevice) {
        return '/user'
      }
    }
  },
  {
    path: '/404',
    component: NotFound, // 404页面组件
    meta: { noNavbar: true, title: "错误页面-智讯系统数据管理后台" } // 可以根据需要设置元信息
  },
  {
    path: '/:catchAll(.*)', // 通配符路由
    redirect: '/404' // 重定向到404页面
  },
];


const router = createRouter({
  history: createWebHistory(),
  routes,
});

// 在路由导航之前设置网页标题
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

// 动态添加路由
dynamicRoutes.forEach(route => {
  router.addRoute(route);
});

router.beforeEach((to, from, next) => {
  const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  const isDingTalk = /DingTalk/i.test(navigator.userAgent); // 新增：判断是否在钉钉中打开网页
  const accessToken = localStorage.getItem("ACCESS_TOKEN");

  // 如果路由需要验证登录，并且没有 ACCESS_TOKEN，重定向到登录页
  const userInfo = JSON.parse(localStorage.getItem("USER_INFO"));
  if (isDingTalk) {
    if (to.meta.requiresAuth && (accessToken == 'null' || accessToken == 'undefined' || accessToken == null)) {
      next('ding-login'); // 这里的 'login' 是你的登录页面的路由名称
    } else {
      next();
    }
  } else if (!isMobileDevice) {
    // 控制是否显示全局页头
    const shouldShowHeader = to.meta.showHeader !== false; // 默认显示，但可以在路由的 meta 中设置 showHeader: false

    // 设置全局页头的显示状态
    if (shouldShowHeader) {
      document.body.classList.remove('hide-global-header');
    } else {
      document.body.classList.add('hide-global-header');
    }

    if (to.meta.requiresAuth && (accessToken == 'null' || accessToken == 'undefined' || accessToken == null)) {
      // 使用 $message
      console.log("sss");
      next('login'); // 这里的 'login' 是你的登录页面的路由名称
    } else if (userInfo != null) {
      // 根据 type 值来决定重定向的路径
      const type = userInfo.type;
      if (type === 3) {
        // 当 type 为 3 时跳转到 /channel/child
        if (to.path === '/channel') {
          next('/channel/child');
        } else {
          next();
        }
      } else {
        next();
      }
    } else {
      next();
    }
  } else {
    if (to.meta.requiresAuth && (accessToken == 'null' || accessToken == 'undefined' || accessToken == null)) {
      next('m-login'); // 这里的 'login' 是你的登录页面的路由名称
    } else {
      next();
    }
  }
});

export default router;
