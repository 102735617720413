<template>
  <el-row :gutter="24" style="max-width: 400px; margin: 0 auto">
    <el-col :span="24">
      <h1>数据管理后台</h1>
      <h5 style="margin-top: 50px">账户登录</h5>
      <el-form label-position="right" size="default">
        <el-form-item label="用户名:" label-width="60px">
          <el-input
            v-model="queryParam.username"
            placeholder="请输入姓名/手机号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="密 码 :" label-width="60px">
          <el-input
            v-model="queryParam.password"
            type="password"
            placeholder="请输入密码"
            show-password
            clearable
            @keydown.enter="verifyUserMsg"
          />
        </el-form-item>

        <el-row>
          <el-checkbox
            v-model="queryParam.remember"
            label="保持登陆"
            style="left: 30px"
          >
            保持登陆
          </el-checkbox>
          <el-col :offset="6" style="margin-top: 40px">
            <el-form-item>
              <el-button
                type="primary"
                @click="verifyUserMsg"
                :autoInsertSpace="true"
                style="width: 200px"
                >登录</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
import { login } from "@/api/login";
// import IdleTimerMixin from "@/utils/idleTimerMixin"; // 导入全局的无操作计时器 mixin

export default {
  // mixins: [IdleTimerMixin], // 混入全局的无操作计时器逻辑
  name: "userLogin",
  data() {
    return {
      queryParam: {
        username: "",
        password: "",
        remember: false,
      },
    };
  },
  computed: {
    rememberValue() {
      // 根据 queryParam.remember 的值返回 1 或 0
      return this.queryParam.remember ? 1 : 0;
    },
  },
  methods: {
    verifyUserMsg() {
      const { username, password } = this.queryParam;
      const obj = {
        username,
        password,
        remember: this.rememberValue,
      };
      console.log(obj);
      login(obj)
        .then((res) => {
          // 处理登录成功的情况
          if (res.success) {
            this.$message.success("登录成功");
            const { token } = res.data;
            // 使用本地存储将令牌和用户信息持久化
            localStorage.setItem("ACCESS_TOKEN", token);
            localStorage.setItem("USER_INFO", JSON.stringify(res.data));
            localStorage.setItem("LASTING", this.rememberValue);
            // if (this.rememberValue == 0) {
            //   console.log("启动");
            //   this.startUserInactiveTimer();
            // }

            this.$router.push("user");
          } else {
            console.log("err", res);
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          // 处理登录失败的情况
          this.$message({
            message: err.message,
            type: "error",
          });
        });
    },
    checkUserAuthentication() {},

    // checkAccessToken() {
    //   const accessToken = localStorage.getItem("ACCESS_TOKEN");

    //   if (accessToken) {
    //     // ACCESS_TOKEN 存在，执行相应的操作
    //     console.log("ACCESS_TOKEN 存在:", accessToken);
    //   } else {
    //     // ACCESS_TOKEN 不存在，执行其他操作
    //     console.log("ACCESS_TOKEN 不存在");
    //   }
    // },
  },
};
</script>

<style scoped>
.el-row {
  margin-bottom: 20px;
  text-align: center;
}
</style>
